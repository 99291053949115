export const ActionTypes = {
  Email: "email",
  SMS: "sms",
  AddToAction: "add to action",
};

export const EventTypes = {
  NodeDataIngestError: 0,
  NodeOffline: 1,
  NodeCreated: 2,
  GatewayOffline: 3,
  GatewayCreated: 4,
};

export const ActionResourceTypes = {
  Node: 0,
  User: 1,
  Company: 2,
  Gateway: 3,
  Action: 4,
  NodeAction: 5,
  GatewayAction: 6,
};

export function getResourceTypeByEventType(eventType, includeSubTypes = false) {
  switch (eventType) {
    case EventTypes.NodeOffline:
      return ActionResourceTypes.Node;
    case EventTypes.NodeDataIngestError:
      return ActionResourceTypes.Node;
    case EventTypes.NodeCreated:
      return includeSubTypes
        ? ActionResourceTypes.NodeAction
        : ActionResourceTypes.Action;
    case EventTypes.GatewayOffline:
      return ActionResourceTypes.Gateway;
    case EventTypes.GatewayCreated:
      return includeSubTypes
        ? ActionResourceTypes.GatewayAction
        : ActionResourceTypes.Action;
    default:
      return null;
  }
}

export const NodeActionTypes = [
  EventTypes.NodeDataIngestError,
  EventTypes.NodeOffline,
  EventTypes.NodeCreated,
];

export const GatewayActionTypes = [
  EventTypes.GatewayOffline,
  EventTypes.GatewayCreated,
];
